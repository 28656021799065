import React from 'react';
//= Layout
import MainLayout from 'layouts/Main';
//= Components
import Navbar from "components/Navbars/ITCreativeNav";
import Blog from 'components/Software/Blog';
import ChatBanner from 'components/Software/ChatBanner';
import Footer from 'components/Saas/Footer';
import { Link } from 'gatsby';
import Blog4 from 'components/Software/Blog4';
import Codei from 'components/Preview/Codei';



const Car4Cash = () => {
  return (
    <MainLayout>
      <Navbar />
      <header className="style-3 overflow-hidden" data-scroll-index="0" style={{background: '#ff5722'}}>
      <div className="container">
        <div className="content section-padding">
          <div className="row">
            <div className="col-lg-5">
              <div className="info" style={{ background: '#00000000'  }}>
                <h1 className="h1">Explore the hidden value of  <span> your car</span></h1>
                <p className="p">Use your car as collateral with our Car4Cash loan</p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-img ">      
        
        <img src="/assets/img/about/style_3_1.png" style={{ maxWidth: '30%'}} alt="" />  
        <img src="/assets/img/about/CAR4CASH.png" alt="" className='img-body' />   
      </div>
    </header>
      <main>
      <section className="about section-padding style-3" data-scroll-index="3">
      
        <div className="container">
          <div className="row gx-0 justify-content-end">
            <div className="col-lg-6">
            <div className="img">
          <img src="/assets/img/about/CAR4CASH.png" alt="" className='img-body' />          
        </div>
            </div>
            <div className="col-lg-6">
              <div className="">
                <div className="section-head long-shape mb-40 style-3">
                  <h3>Car4Cash <span> Loan</span></h3>
                </div>
                <p>
                Need cash fast? Use your car as collateral with our Car4Cash loan. Borrow against the value of your vehicle. We offer quick approval and flexible terms so you can get the cash you need without selling your car.
                </p>
               
                <div className="text mb-20">
                  <h5>Requirements: </h5>
                  <ul>
                    <li>Car</li>
                    <li>BVN</li>
                    <li>Email</li>
                    <li>Employer or Business Details</li>
                    <li>Valuation Report</li>                    
                    <li>Loan amount & Tenor</li>
                  </ul>
                </div>
                <Link to="/#" className="btn rounded-pill bg-blue2 sm-butn text-white mt-10">
                  <span>Apply for IPPIS Loan</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
    </section>
       
        <Blog4 />
        <Codei /> 
        <ChatBanner />
      </main>
      <Footer />
    </MainLayout>
  )
}

export const Head = () => {
  return (
    <>
      <title>Mutual Trust MFB</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  )
}

export default Car4Cash;